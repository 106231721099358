<template>
  <CCard>
    <CCardHeader>
      <CCol>
        <h4 class="subhead-modal custom-header">Master Segment</h4>
        <CButton color="primary" class="mr-1 custom-header-button" @click="OnClickDetail(null)">New</CButton>
      </CCol>
      
      <!-- <div class="subhead-modal-action">
      </div> -->
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <CDataTable
            :items="SegmentData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
            tableFilter
          >
            <template #IsActive="{item}">
              <td>
                <CBadge :color="GetBadge(item.IsActive)">
                  {{item.IsActive == true ? "Active" : "InActive"}}
                </CBadge>
              </td>
            </template>
            <template #show_details="{item, index}">
              <td class="py-2">
                <CButton
                  color="success"
                  variant="outline"
                  square
                  size="sm"
                  @click="OnClickDetail(item, index)"
                >
                  Edit
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>

      <AddOrUpdateSegmentModal
        :showModal.sync="showAddOrUpdateModal"
        @AddOrUpdateSegmentModal-updated="showAddOrUpdateModal = $event"
        :item.sync="SegmentDataObj"
        :viewmode="IdeationManagerViewmode"
        @save-segment-success="GetSegmentData()"
      />

    </CCardBody>
  </CCard>
</template>
<style>
.custom-header {
  padding-top: 5px;
  width: 250px;
  display: inline-block;
}
.custom-header-button {
  float: right;
}
</style>

<script>

import axios from "axios";
import store from "../../store";
import AddOrUpdateSegmentModal from "@/components/modal/AddOrUpdateSegmentModal.vue";

const fields = [
  { key: "SegmentDesc", label: "Segment Name" },
  { key: "IndustryDesc", label: "Industry Name" },
  { key: "IsActive", label: "Status" },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
];

export default {
  name: "MasterSegment",
  components: {
    AddOrUpdateSegmentModal,
  },
  data() {
    return {
      fields,
      SegmentData: [],
      IsLoading: false,
      showAddOrUpdateModal: false,
      SegmentDataObj: null,
      IdeationManagerViewmode: "view",
    };
  },
  mounted() {
    this.GetSegmentData();
  },
  methods: {

    GetSegmentData() {
      axios.get(store.getters.URL + "/api/MasterSegment/get-all-table")
      .then(res => {
        //console.log("res",res);
        this.SegmentData = res.data;
      })
      .catch(err => {
        console.error("err",err);
      });
    },
    GetBadge (status) {
      switch (status) {
        case true : return "success"
        case false : return "danger"
        default: "primary"
      }
    },
    OnClickDetail(item){
      this.showAddOrUpdateModal = true;
      this.SegmentDataObj = item;
      this.IdeationManagerViewmode = item === null ? "create" : "edit";
    }

  },
};
</script>
