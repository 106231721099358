<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="CloseModal()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">Industry</label>
              <div class="col-md-9">
                <v-select
                  ref="InduustrySelectRef"
                  v-model="InduustrySelect"
                  :value.sync="InduustrySelect"
                  :options="IndustryItem"
                  placeholder="-- Select --"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CInput
              ref="SegmentNameRef"
              label="Segment name"
              horizontal
              v-model="SegmentData.SegmentName"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">Status</label>
              <div class="col-md-9">
                <CSwitch
                  style="padding-top: 5px"
                  color="info"
                  shape="pill"
                  labelOn="on"
                  labelOff="off"
                  :checked.sync="SegmentData.isActive"
                />
              </div>
            </div>
            <!-- <div class="col-md-3">
              <label class="">Status</label>
            </div> -->
            <!-- <div class="col-md-9 pr-0">
              
            </div> -->
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton
          @click="SaveData('Save')"
          v-if="viewmode !== 'view'"
          color="primary"
        >
          Save
        </CButton>
        <CButton @click="CloseModal()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";

var URL_Insert = `${store.getters.URL}/api/MasterSegment/insert`;
var URL_Update = `${store.getters.URL}/api/MasterSegment/update`;
var URL_GetIndustry = `${store.getters.URL}/api/MasterIndustry/getall`;
const titleAdd = "Add Segment";
const titleEdit = "Edit Segment";

export default {
  name: "AddOrUpdateSegmentModal",
  components: {
    vSelect,
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      titleModal: titleAdd,
      IndustryItem: [{ value: 0, label: "-- Select --" }],
      InduustrySelect: { value: 0, label: "-- Select --" },
      SegmentData: {
        SegmentId: 0,
        SegmentName: null,
        IndustryId: 0,
        isActive: true,
      },
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false,
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("AddOrUpdateSegmentModal-updated", v);
      },
    },
  },
  mounted() {
    this.GetIndustryItem();
  },
  methods: {
    setDefault() {
      this.SegmentData.SegmentId = 0;
      this.SegmentData.SegmentName = null;
      this.SegmentData.IndustryId = null;
      this.SegmentData.isActive = true;
      this.InduustrySelect = { value: 0, label: "-- Select --" };

      this.$refs.SegmentNameRef.$el.children[1].children[0].style.borderColor =
        "";
      this.$refs.InduustrySelectRef.$el.children[0].style.borderColor = "";
    },
    CloseModal() {
      this.isshow = false;
      this.setDefault();
    },
    validateFiled() {
      let a = true;
      let b = true;
      const warnSegment = "Please enter segment name.";
      const warnIndustry = "Please select industry";
      if (
        this.SegmentData.SegmentName == null ||
        this.SegmentData.SegmentName == ""
      ) {
        this.$refs.SegmentNameRef.$el.children[1].children[0].style.borderColor =
          "red";
        a = false;
      } else {
        this.$refs.SegmentNameRef.$el.children[1].children[0].style.borderColor =
          "";
      }

      if (this.InduustrySelect?.value == 0) {
        this.$refs.InduustrySelectRef.$el.children[0].style.borderColor = "red";
        b = false;
      } else if (this.InduustrySelect == null) {
        this.$refs.InduustrySelectRef.$el.children[0].style.borderColor = "red";
        b = false;
      } else {
        this.$refs.InduustrySelectRef.$el.children[0].style.borderColor = "";
      }
      if (!a || !b) {
        this.$_toast_Noti("error", "error", "Please enter data require.");
      }
      return a == true && b == true;
    },
    SaveData(action) {
      let reVal = false;
      reVal = this.validateFiled();
      if (reVal) {
        if (this.SegmentData.SegmentId > 0) {
          this.UpdateData();
        } else {
          this.AddData();
        }
      }
      //console.log("SS", this.InduustrySelect);
    },
    GetIndustryItem() {
      axios
        .get(URL_GetIndustry)
        .then((res) => {
          let options = [{ value: 0, label: "-- Select --" }];
          if (res !== null) {
            res.data.forEach(function (data) {
              options.push({
                value: data.IndustryID,
                label: data.IndustryDesc,
              });
            });
            this.IndustryItem = options;
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    AddData() {
      axios
        .post(URL_Insert, this.mapDTO(this.SegmentData))
        .then((res) => {
          if (res.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.CloseModal();
            this.$emit("save-segment-success");
          }
        })
        .catch((err) => {
          console.error("err insret segment", err);
        });
    },
    UpdateData() {
      axios
        .put(URL_Update, this.mapDTO(this.SegmentData))
        .then((res) => {
          if (res.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.CloseModal();
            this.$emit("save-segment-success");
          }
        })
        .catch((err) => {
          console.error("err update segment", err);
        });
    },
    mapDTO(item) {
      let param = {
        SegmentID: 0,
        SegmentDesc: null,
        IndustryID: 0,
        IsActive: true,
      };

      param.SegmentID = item.SegmentId;
      param.SegmentDesc = item.SegmentName;
      param.IndustryID = this.InduustrySelect.value;
      param.IsActive = item.isActive;

      return param;
    },

    bindDataToDdls(id) {
      let result = null;
      result = this.IndustryItem.filter((e) => e.value == id);
      if (result != null) {
        this.InduustrySelect = result[0];
      }
    },
  },
  watch: {
    showModal: function (newVal, oldVal) {
      // console.log("showModal", newVal);
      // console.log("item", this.item);
      if (newVal) {
        let temp = this.item;
        if (temp != null) {
          this.titleModal = titleEdit;
          this.SegmentData.SegmentId = temp.SegmentID;
          this.SegmentData.SegmentName = temp.SegmentDesc;
          this.bindDataToDdls(temp.IndustryID);
          this.SegmentData.isActive = temp.IsActive;
        } else {
          this.titleModal = titleAdd;
          this.setDefault();
        }
      }
    },
  },
};
</script>
